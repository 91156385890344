
//コンテンツ部分
$(function() {
  
  $('#contents-item01').on('click mouseenter', function () {     
    $(this).toggleClass('active');
  });

  $('#contents-item02, #contents-item03, #contents-item04').on('click mouseenter', function () {     
    $('#contents-item01').removeClass('active');
  });

  $('#contents-item02').on('click mouseenter', function () {     
    $(this).toggleClass('active');
  });

  $('#contents-item01, #contents-item03, #contents-item04').on('click mouseenter', function () {     
    $('#contents-item02').removeClass('active');
  });

  $('#contents-item03').on('click mouseenter', function () {     
    $(this).toggleClass('active');
  });

  $('#contents-item01, #contents-item02, #contents-item04').on('click mouseenter', function () {     
    $('#contents-item03').removeClass('active');
  });

  $('#contents-item04').on('click mouseenter', function () {     
    $(this).toggleClass('active');
  });

  $('#contents-item01, #contents-item02, #contents-item03').on('click mouseenter', function () {     
    $('#contents-item04').removeClass('active');
  });

}); 

//スクロール時、リロード時の処理
function fadeAnime() {

  $('.text-br02, .yellow').each(function() {

    var top = $(".text-br02, .yellow").offset().top;   
    var position = top - $(window).height() + 30; 

    if($(window).scrollTop() > position){
      $('.text-br02, .yellow').addClass('visible');
    }else{
      $('.text-br02, .yellow').removeClass('visible');
    }
  })
  
}

$(window).on('scroll', function (){
  fadeAnime();
});

$(window).on('load', function(){
  fadeAnime();
});